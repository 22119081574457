<template>
  <div class="device_div iframe_overflow">
    <div class="create_btn">
      <Button icon="ios-add" @click="addFn">CREATE</Button>
    </div>
    <iframe
      class="iframe_div"
      ref="iframe_div"
      :src="url"
      @load="checkIframe"
    ></iframe>
    <div class="iframe_shadow" v-if="!type"></div>
    <Modal
      v-model="showCreate"
      title="Create Device"
      :width="800"
      footer-hide
      @on-visible-change="changeModal"
    >
      <iframe class="iframe_div" :src="addUrl" v-if="addUrl"></iframe>
    </Modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "",
      addUrl: "",
      showCreate: false,
      observer: null,
      type: true
    };
  },
  methods: {
    init() {
      this.url = IOT_CONFIG.paths["device"];
      this.addUrl = IOT_CONFIG.paths["device/create"];
      let key = this.$route.query.type;
      if (key === 'view') {
        this.type = false;
      } else {
        this.type = true;
      }
    },
    checkIframe() {
      const Iframe = this.$refs.iframe_div;
      if (Iframe) {
        var href = Iframe.contentWindow.location.href;
      }
      if (href && href != this.url) {
        this.checkBtnFn(Iframe.document);
      }
      console.log(Iframe.contentWindow.location.href);
    },
    checkBtnFn(idoc) {
      if (!idoc) {
        return;
      }
      var buttons = idoc.getElementsByClassName("MuiButton-textPrimary");
      if (buttons && buttons.length) {
        var btn = buttons[0];
        btn.setAttribute(
          "class",
          "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary"
        );
        btn.removeAttribute("disabled");
        console.log(buttons);
        console.log(btn);
      }
    },
    addFn() {
      this.showCreate = true;
      this.url = "";
    },
    changeModal(status) {
      if (status == false) {
        this.url = IOT_CONFIG.paths["device"];
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang='less'>
.device_div {
  position: relative;
  .create_btn {
    position: absolute;
    top: 116px;
    right: 60px;
  }
}
</style>